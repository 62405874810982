<template>
  <b-container fluid class="py-5">
    <b-card>
      <b-row>
          <b-col class="text-center">
            <strong> Tipo de Persona </strong>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <svg style="width: 92px; height: 92px" viewBox="0 0 24 24">
              <path
                fill="#005D7B"
                d="M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17M13 18H3V8L11 13L19 8V13H21V6C21 4.9 20.1 4 19 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H13V18M19 6L11 11L3 6H19Z"
              />
            </svg>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="text-center">
            Seleccione a continuación que tipo de persona eres
          </b-col>
        </b-row>

         <b-card
          @click="form.data.personType = 'F'"
          class="buttonCard p-0 align-middle mt-2"
          :class="{'card-selected': form.data.personType === 'F'}"
        >
        <b-row align-v="center">
          <b-col cols="8">
            <strong>Persona Física</strong>
          </b-col>
          <b-col class="text-right small-icon" cols="4">
            <em
              class="text-success fas fa-check-circle"
              v-if="form.data.personType === 'F'"
            ></em>
          </b-col>
        </b-row>
      </b-card>

      <b-card
        @click="form.data.personType = 'J'"
        class="buttonCard p-0 align-middle mt-2"
        :class="{'card-selected': form.data.personType === 'J'}"
      >
        <b-row align-v="center">
          <b-col cols="8">
            <strong>Persona Jurídica</strong>
          </b-col>
          <b-col class="text-right small-icon" cols="4">
            <em
              class="text-success fas fa-check-circle"
              v-if="form.data.personType === 'J'"
            ></em>
          </b-col>
        </b-row>
      </b-card>

        <b-button
          class="align-items-center mt-2 button-shadow"
          block
          v-b-tooltip.hover
          title="Siguiente"
          @click.prevent="confirmStep"
          variant="primary"
          :disabled="
            $v.$anyError || this.loading || emptyField
          "
        >
        <!-- falta {!$v.$anyDirty ||} en disabled -->
          <span v-if="!this.loading" class="mb-2  form-text">SIGUIENTE</span>
          <b-spinner type="grow" v-else label="Loading..."></b-spinner>
        </b-button>

      <b-row class="pt-2 mt-2">
        <stepper class="stepper" :filled="currentStep" :size="maxSteps"/>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import stepsMixin from '@/mixins/stepsMixin'

export default {
  name: "SelectPersonType",
  components: {
    Stepper: () => import("@/views/Components/Giftcards/Stepper"),
  },
  mixins: [stepsMixin],
  data() {
    return {
      form: {
        data: { personType: "" },
      },
      loading: false,
      clicked: false,
    }
  },
  computed: {
    emptyField: function () {
      return (
        this.form.data.personType === ""
      )
    },
  },
  methods: {
    confirmStep() {
      this.$emit("confirm-step", this.form)
    },
  },
  validations: {
    form: {
      data: {
        personType: {
          required,
        },
      },
    },
  },
}
</script>

<style>
.unclickedPicture {
  background-color: rgba(0, 0, 0, 0.233);
}
.clickedPicture {
  background-color: rgba(0, 0, 0, 0.877);
}

.unclickedCard {
  color: rgba(0, 0, 0, 0.384);
}
.clickedCard {
  color: rgba(0, 0, 0, 0.877);
  border: 2px solid #352f77;
}

.buttonCard:hover {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.buttonCard {
  border: 1px solid #D5D5D5;
  border-radius: 4px;
  background-color: rgba(255,255,255,0);
  cursor: pointer;
}

.buttonCard:active {
  left: 1px;
  top: 1px;
  background-color: rgb(245, 245, 245);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.small-icon{
  height: 25px;
}

.card-selected{
  background-color: rgba(0,93,123,0.05);
  border: 1px solid #005D7B;
}
</style>
